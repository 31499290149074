var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"basic"},[_c('div',{staticClass:"basic__select"},[_c('div',{staticClass:"basic__select-item"},[_c('p',[_vm._v(_vm._s(_vm.$t('year')))]),_c('ui-select',{attrs:{"options":_vm.getYears},on:{"input":_vm.callIndicatorsApi},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1)]),_c('UiLoader',{model:{value:(_vm.loader),callback:function ($$v) {_vm.loader=$$v},expression:"loader"}}),_c('UiNotification',{attrs:{"message":_vm.message},model:{value:(_vm.showNotification),callback:function ($$v) {_vm.showNotification=$$v},expression:"showNotification"}}),_vm._l((_vm.baseData),function(item,key){return _c('Table',{directives:[{name:"show",rawName:"v-show",value:(_vm.year.value && !_vm.loader && item?.items.length),expression:"year.value && !loader && item?.items.length"}],key:key,ref:"table",refInFor:true,attrs:{"title":_vm.$t('baseline'),"descriptionList":[
        {title: _vm.$t('code'), width: 80},
        {title: _vm.$t('type-2'), width: 420},
        {title: _vm.$t('unit-of-measurement'), width: 160, paddingLeft: 0, textAlign: 'center'},
        {title: _vm.$t('field-type'), width: 160, paddingLeft: 0, textAlign: 'center'}
      ],"actionText":_vm.$t('add-line'),"data":item.items,"sort":true,"hideEditIcon":"","isBase":"","isCode":""},on:{"addRowField":function($event){return _vm.addBaseIndicator($event, item.id)},"changeRowField":_vm.editBaseIndicator,"deleteRowField":_vm.deleteBaseIndicator}})}),(_vm.isEmpty && !_vm.loader)?[_c('Table',{ref:"table",attrs:{"title":_vm.$t('baseline'),"descriptionList":[
          {title: _vm.$t('code'), width: 80},
          {title: _vm.$t('type-2'), width: 420},
          {title: _vm.$t('unit-of-measurement'), width: 160, paddingLeft: 0, textAlign: 'center'},
          {title: _vm.$t('field-type'), width: 160, paddingLeft: 0, textAlign: 'center'}
        ],"actionText":_vm.$t('add-line'),"sort":true,"hideEditIcon":"","isBase":"","isCode":""},on:{"addRowField":function($event){return _vm.addBaseIndicator($event)},"changeRowField":_vm.editBaseIndicator,"deleteRowField":_vm.deleteBaseIndicator}})]:_vm._e(),_c('div',{staticClass:"basic__actions"},[(_vm.year.value && !_vm.loader && !_vm.baseData[0]?.items.length && !_vm.isEmpty)?_c('ui-button',{attrs:{"color":"error"},on:{"click":_vm.addNewTable}},[_c('img',{attrs:{"src":require("@/assets/svg/icon-plus-white.svg"),"alt":""}}),_vm._v(" "+_vm._s(_vm.$t('new-table'))+" ")]):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }