<template>
  <div class="basic">
    <div class="basic__select">
      <div class="basic__select-item">
        <p>{{ $t('year') }}</p>
        <ui-select v-model="year" :options="getYears" @input="callIndicatorsApi"/>
      </div>
    </div>
    <UiLoader v-model="loader" />
    <UiNotification v-model="showNotification" :message="message" />
    <Table
        v-show="year.value && !loader && item?.items.length"
        v-for="(item,key) in baseData"
        :title="$t('baseline')"
        :descriptionList="[
          {title: $t('code'), width: 80},
          {title: $t('type-2'), width: 420},
          {title: $t('unit-of-measurement'), width: 160, paddingLeft: 0, textAlign: 'center'},
          {title: $t('field-type'), width: 160, paddingLeft: 0, textAlign: 'center'}
        ]"
        :actionText="$t('add-line')"
        :data="item.items"
        :sort="true"
        hideEditIcon
        ref="table"
        :key="key"
        isBase
        @addRowField="addBaseIndicator($event, item.id)"
        @changeRowField="editBaseIndicator"
        @deleteRowField="deleteBaseIndicator"
        isCode
    />
    <template v-if="isEmpty && !loader">
      <Table
          :title="$t('baseline')"
          :descriptionList="[
            {title: $t('code'), width: 80},
            {title: $t('type-2'), width: 420},
            {title: $t('unit-of-measurement'), width: 160, paddingLeft: 0, textAlign: 'center'},
            {title: $t('field-type'), width: 160, paddingLeft: 0, textAlign: 'center'}
          ]"
          :actionText="$t('add-line')"
          :sort="true"
          hideEditIcon
          ref="table"
          isBase
          isCode
          @addRowField="addBaseIndicator($event)"
          @changeRowField="editBaseIndicator"
          @deleteRowField="deleteBaseIndicator"
      />
    </template>
    <div class="basic__actions">
      <ui-button v-if="year.value && !loader && !baseData[0]?.items.length && !isEmpty" color="error" @click="addNewTable">
        <img src="@/assets/svg/icon-plus-white.svg" alt="">
        {{ $t('new-table') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Table from "@/components/ui/UiTable.vue";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiNotification from "@/components/ui/UiNotification.vue";
import UiButton from "@/components/ui/UiButton.vue";

export default {
  name: "Basic",
  components: {UiButton, UiNotification, UiLoader, Table, UiSelect},
  data() {
    return {
      year: {},
      loader: false,
      showNotification: false,
      message: '',
      isEmpty: false,
      baseData: [],
    }
  },

  computed: {
    ...mapGetters([
      'getIndicators',
      'getBaseBlocks',
      'getYears'
    ]),
  },

  methods: {
    ...mapActions([
      'years',
      'indicators',
      'addIndicators',
      'baseBlocks',
      'editIndicators',
      'deleteIndicators',
    ]),
    ...mapMutations(['setYears']),

    addNewTable() {
      this.isEmpty = true
      setTimeout(() => {
        this.$refs.table.addItem();
      }, 100)
    },

    loadPage(message) {
      this.baseData = []
      this.loader = true
      this.baseBlocks({year: this.year.value}).then(() => {
        this.loader = false
        this.getBaseBlocks.forEach(el => {
          const payload = {
            type: 'Base',
            rrSpecBlockId: el.id,
            year: this.year.value,
            groupId: null,
          }
          this.indicators(payload).then(() => {
            let data = {
              title: el.name,
              id: el.id,
              items: this.getIndicators,
            }
            this.baseData.push(data)
            this.baseData.sort((a, b) => a.id-b.id)
            setTimeout(() => {
              this.showNotification = true
              this.loader = false;
              this.message = message
            }, 500)
          })
        })
      })
    },

    callIndicatorsApi() {
      this.baseData = []
      this.loader = true
      this.baseBlocks({year: this.year.value}).then(() => {
        this.loader = false
        this.getBaseBlocks.forEach(el => {
          const payload = {
            type: 'Base',
            rrSpecBlockId: el.id,
            year: this.year.value,
            groupId: null,
          }
          this.indicators(payload).then(() => {
            let data = {
              title: el.name,
              id: el.id,
              items: this.getIndicators,
            }
            this.baseData.push(data)
            this.baseData.sort((a, b) => a.id-b.id)
          })
        })
      })
    },

    addBaseIndicator (data) {
      this.isEmpty = false;
      this.loader = true;
      const payload = {
        type: 'Base',
        year: this.year.value,
        name: data.name,
        measureName: data.measureName,
        ord: +data.ord,
        dataType: data.baseTypeField,
        rrSpecBlockId: this.year.value === 2023 ? 1 : 4,
        code: data.code
      }
      this.addIndicators(payload).then(() => {
        this.loadPage(this.$t('type-successfully-added'))
      })
    },

    editBaseIndicator (data) {
      this.loader = true;
      const payload = {
        type: 'Base',
        year: this.year.value,
        name: data.name,
        measureName: data.measureName,
        ord: +data.ord,
        dataType: data.baseTypeField,
        id: data.id,
        code: data.code
      }
      this.editIndicators(payload).then(() => {
        this.loadPage(this.$t('type-successfully-updated'))
      })
    },

    deleteBaseIndicator (data) {
      this.loader = true;
      this.deleteIndicators({id: data.id}).then(() => {
        this.loadPage(this.$t('type-successfully-deleted'))
      })
    }
  },

  mounted() {
    this.setYears([])
    this.years().then((res) => {
      let data = res.map((elem) => {
        elem.name = elem.id
        elem.value = elem.id
        return elem
      })
      this.loader = false;
      this.setYears(data)
    })
  }
}
</script>

<style lang="scss" scoped>
.basic {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 500px;

  @media (max-width: 1000px) {
    overflow-x: auto;
  }

  &__select {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 433px;
    width: 100%;

    ::v-deep .ui-select {
      z-index: auto;
      max-width: 305px;
    }
    ::v-deep .ui-select__label {
      display: none;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      p {
        font-size: 16px;
        line-height: 19px;
        color: #1B1A1F;
        white-space: nowrap;
      }
    }
  }

  &__actions {
    button {
      max-width: 366px;
    }
  }
}
</style>
